"use client";

import { Button, Container, Stack } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { FC } from "react";
import { EmptyStateMessage } from "~/lib/components/EmptyStateMessage";

const RootError: FC = () => {
  const router = useRouter();

  return (
    <Container h="100vh">
      <Stack gap="xl" align="center">
        <EmptyStateMessage imageSrc="/empty-state/47.svg" message="Bir hata oluştu" />

        <Button leftSection={<IconChevronLeft />} onClick={() => router.back()}>
          Geri Dön
        </Button>
      </Stack>
    </Container>
  );
};

export default RootError;
